import React, { useState, useEffect } from 'react';
import axios from "axios";
import { BASE_URL_API } from "../environment";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Link, useNavigate } from 'react-router-dom';
import { Pagination } from 'react-bootstrap';
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
export const Orders = () => {
  var adm = localStorage.getItem('token');
  const [orderData, setOrderData] = useState([]);

  const navigate = useNavigate();
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });
  // today date in dd-mm-yyyy format.
  const getFormattedDate = (dateString) => {
    if (!dateString) {
      console.error("Date string is null or empty.");
      return dateString;
    }
    
    let date;
    if (dateString instanceof Date) {
      date = dateString;
    } else {
      const dateParts = dateString.split("-");
      if (dateParts.length === 3) {
        if (dateParts[0].length === 4) {
          date = new Date(`${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`);
        } else {
          date = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
        }
      } else {
        console.error("Invalid date format:", dateString);
        return dateString;
      }
    }
  
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  
  // date in dd-mm-yyyy format.
  const formatDateddmmyy = (dateString) => {
    const parts = dateString.split("-");
    if (parts.length === 3) {
      const day = parts[2];
      const month = parts[1];
      const year = parts[0];
      return `${day}-${month}-${year}`;
    }
    return dateString;
  };

  // after 7 date in dd-mm-yyyy format.
  const getDateAfterDays = (days) => {
    if (!days) {
      console.error("Date string is null or empty.");
      return days;
    }

    const today = new Date();
    const dateAfterDays = new Date(today.setDate(today.getDate() + days));
    return getFormattedDate(dateAfterDays); // Corrected line
  };

  
  const [fromDate, setFromDate] = useState(getFormattedDate(new Date()));
  const [toDate, setToDate] = useState(getFormattedDate(getDateAfterDays(6)));
  const [cityid, setCityId] = useState(null);
  const [selectcityid, setSelectcityid] = useState("");
  const [totaloffset, setTotalOffset] = useState(null);
  const [offset, setOffset] = useState(1);
  const [orderId, setOrderId] = useState("");
  const [customername, setCustomerName] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [scdate, setScdate] = useState("");
  const [mobileno, setMobileNo] = useState("");
  const [organisation, setOrganisation] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [agentname, setAgentName] = useState("");
  
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false); 
  const [sattle, setSattle] = useState(false); 
  const [mode, setMode] = useState("");
  const [transctionid, setTransactionId] = useState("");
  const [settledate, setSettleDate] = useState("");
  const [notes, setNotes] = useState("");
  useEffect(() => {
    fetchData();
  }, [adm, fromDate, toDate, selectcityid, offset, statusFilter, scdate, orderId, customername, organisation, mobileno, agentname]);

//getting order date and setting in variable
  const fetchData = () => {
    axios
      .get(BASE_URL_API + "orders/adminhistorder/" + adm+"?startDate="+formatDateddmmyy(fromDate)+"&endDate="+formatDateddmmyy(toDate)+"&offset="+offset+"&cityName="+selectcityid+"&status="+statusFilter+"&scheduledate="+formatDateddmmyy(scdate)+"&orderId="+orderId+"&customerName="+customername+"&mobileNumber="+mobileno+"&organisation="+organisation+"&agent="+agentname)
      .then((res) => {
        console.log("total count data is"+Math.ceil(res.data.count));
        const totalOffset = Math.ceil(res.data.count/ 10);
        setTotalOffset(totalOffset);
        console.log("totaloffsetis"+totalOffset);
        setTotalItems(res.data.count);
        setOrderData(res.data.data);        
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setOrderData([]);
      });

      axios
      .get(BASE_URL_API + "items/allCityPercent")
      .then((res) => setCityId(res.data))
      .catch((err) => console.log(err));
  };
  const [agent, setAgent] = useState([]);
  
  useEffect(() => {
    axios.get(BASE_URL_API + "users/allApprovedUsers")
      .then(res => {
        setAgent(res.data);
      })
      .catch(err => console.log(err))
  },[]);
//page change
  const handlePageChange = (pageNumber) => {
    setOffset(pageNumber);
  };

  const startItem = (offset - 1) * 10 + 1;
  const endItem = Math.min(offset * 10, totalItems);

  //Date changing for setting startdate and end date
  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
    setOffset(1);
    setIsAllSelected(false);
    setSelectedOrders([]);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
    setOffset(1);
    setIsAllSelected(false);
    setSelectedOrders([]);
  };

  // Pagination function
  const renderPaginationItems = () => {
    const paginationItems = [];
    const maxPagesToShow = 7; // Show at most 7 pages in the pagination control
    const startPages = 3; // Show the first 3 pages
    const endPages = 2; // Show the last 2 pages

    if (totaloffset <= maxPagesToShow) {
      for (let page = 1; page <= totaloffset; page++) {
        paginationItems.push(
          <Pagination.Item key={page} active={offset === page} onClick={() => handlePageChange(page)}>
            {page}
          </Pagination.Item>
        );
      }
    } else {
      // Show first 3 pages
      for (let page = 1; page <= startPages; page++) {
        paginationItems.push(
          <Pagination.Item key={page} active={offset === page} onClick={() => handlePageChange(page)}>
            {page}
          </Pagination.Item>
        );
      }

      // Show ellipsis if necessary
      if (offset > startPages + 1) {
        paginationItems.push(<Pagination.Ellipsis key="start-ellipsis" />);
      }

      // Show the middle pages around the current page
      const middlePages = [];
      const startMiddlePage = Math.max(offset - 1, startPages + 1);
      const endMiddlePage = Math.min(offset + 1, totaloffset - endPages);

      for (let page = startMiddlePage; page <= endMiddlePage; page++) {
        middlePages.push(
          <Pagination.Item key={page} active={offset === page} onClick={() => handlePageChange(page)}>
            {page}
          </Pagination.Item>
        );
      }

      paginationItems.push(...middlePages);

      // Show ellipsis if necessary
      if (offset < totaloffset - endPages - 1) {
        paginationItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
      }

      // Show last 2 pages
      for (let page = totaloffset - endPages + 1; page <= totaloffset; page++) {
        paginationItems.push(
          <Pagination.Item key={page} active={offset === page} onClick={() => handlePageChange(page)}>
            {page}
          </Pagination.Item>
        );
      }
    }

    return paginationItems;
  };

  useEffect(() => {
    console.log('Selected order IDs:', selectedOrders);
  }, [selectedOrders]);
  //selecting orders
  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedOrders([]);
    } else {
      const ordersToSelect = orderData
        .filter(data => data.status === 'Pickup Completed')
        .map(data => data.order_id);
      setSelectedOrders(ordersToSelect);
    }
    setIsAllSelected(!isAllSelected);
  };

  const handleSelectOrder = (id) => {
    const newSelectedOrders = selectedOrders.includes(id)
      ? selectedOrders.filter((orderId) => orderId !== id)
      : [...selectedOrders, id];
    
    setSelectedOrders(newSelectedOrders);
    setIsAllSelected(newSelectedOrders.length === orderData.length);
  };
  //order settle function
  const handleSettle = () => {
    if (!mode || !transctionid || !settledate || !notes ) {
      swalWithBootstrapButtons.fire({
        title: "Error",
        text: "All fields are required!",
        icon: "error",
      });
      return;
    }
  
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You want to settle these orders!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          axios
            .post(BASE_URL_API + "orders/adminSettlment",{
              orderID: selectedOrders,
              mode: mode,
              transctionId: transctionid,
              settlement_date: formatDateddmmyy(settledate),
              notes: notes
            })
            .then((res) => {
              swalWithBootstrapButtons.fire(
                "Updated!",
                "These orders have been settled successfully.",
                "success"
              );
              console.log(res.data);
              setSattle(false);
              fetchData();
              setIsAllSelected(false);
              setSelectedOrders([]);
            })
            .catch((err) => console.log(err));
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "You did not settled these orders. Please try again",
            "error"
          );
          setSattle(false);
        }
      });
  };

  //navigate to another page for settlement
  const settlementPage =() =>{
    navigate('/dashboard/orders/settlement');
  }
  return (
    <div className='card'>
     <h5 className="card-header">All Orders</h5>
     <div className="d-flex justify-content-between"> 
      <div class="d-grid gap-2 d-md-flex
       justify-content-md-end p-2 ">
        <select onChange={(e)=> setSelectcityid(e.target.value)} value={selectcityid} className="form-select form-select-sm " style={{ width: '150px', height: '40px', borderColor:"#73be44" }}>
        <option value="">All Cities</option>
          {cityid && cityid.cities
            ? cityid.cities.map((item) => (
                <option key={item._id} value={item.city}>
                  {item.city}
                </option>
              ))
            : null}
        </select>
        <button type="button" className="btn btn-md" style={{color:"white", backgroundColor:"#73be44"}} 
        // onClick={()=>setSattle(true)} 
        // disabled={selectedOrders.length === 0}
        onClick={settlementPage}
        >Order Settlement</button>
      </div>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end p-2 ">      
          <label className="mx-2 "><b>From Date :</b></label>
          <input type="date" onChange={handleFromDateChange} value={fromDate} className='p-1 border border-primary text-primary' max={toDate} />
          &nbsp;  &nbsp;
          <label className="mx-2 "><b>To Date :</b></label>
          <input type="date" onChange={handleToDateChange} value={toDate} className='p-1 border border-primary text-primary'  min={fromDate} />
        </div>
      </div>
      {/* Total order showing in table */}
      <div className="">
        <div
          className="table-responsive table-bordered"
          style={{ minHeight: "75vh", overflowY: "auto" }}
        >
          <table className="table mb-0">
            <thead style={{backgroundColor:"#E6F8DB"}}>
              <tr>
                {/* <th>
                  <input
                    type="checkbox"
                    checked={isAllSelected}
                    onChange={handleSelectAll}
                  />
                </th> */}
                {/* <th>Sl no</th> */}
                <th>
                  Order ID
                  <br />
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control"
                    value={orderId}
                    onChange={(e) => {setOrderId(e.target.value); setOffset(1); setIsAllSelected(false); setSelectedOrders([]);}}
                  />
                </th>
                {/* <th>
                  Customer Name
                  <br />
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control"
                    value={customername}
                    onChange={(e) => {setCustomerName(e.target.value); setOffset(1); setIsAllSelected(false); setSelectedOrders([]);}}
                  />
                </th> */}
                <th>
                  Contact No
                  <br />
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control"
                    value={mobileno}
                    onChange={(e) => {setMobileNo(e.target.value); setOffset(1);  setIsAllSelected(false); setSelectedOrders([]);}}
                  />
                </th>
                <th>
                  Agent Name
                  <br />
                  <select className='form-select form-select-md'
                        onChange={(e) => {setAgentName(e.target.value); setOffset(1);}}
                        value={agentname}
                      >
                        <option value="">
                          All Agent
                        </option>
                        {agent.map(
                          (agentItem) =>
                            agentItem.isActive &&
                            agentItem._id !==
                              orderData?.data?.result[0]?.agent_id && (
                              <option key={agentItem._id} value={agentItem.name}>
                                {agentItem.name}
                              </option>
                            )
                        )}
                      </select>
                </th>
                {/* <th>
                  City
                  <br />
                  <select onChange={(e)=> {setSelectcityid(e.target.value); setIsAllSelected(false); setOffset(1); setSelectedOrders([]);}} value={selectcityid} className="form-select form-select-sm " style={{ width: '100px', height: '40px', borderColor:"#73be44" }}>
                  <option value="">All Cities</option>
                    {cityid && cityid.cities
                      ? cityid.cities.map((item) => (
                          <option key={item._id} value={item.city}>
                            {item.city}
                          </option>
                        ))
                      : null}
                  </select>
                </th> */}
                {/* <th>
                  Organisation
                  <br />
                  <select
                    className="form-select form-select-md"
                    value={organisation}
                    onChange={(e) => {setOrganisation(e.target.value); setOffset(1); setIsAllSelected(false); setSelectedOrders([]);}}
                    >
                    <option value="">ALL</option>
                    <option value="YII">YII</option>
                    <option value="CII">CII</option>
                    <option value="OTHERS">OTHERS</option>
                  </select>
                </th> */}
                <th>
                  Address
                  <br />
                </th>
                <th>
                  Pickup Date
                  <br />
                  <input
                    type="date"
                    className="form-control"
                    style={{ width: "100%" }}
                    value={scdate}
                    onChange={(e) => {setScdate(e.target.value); setOffset(1); setIsAllSelected(false); setSelectedOrders([]);}}
                    min={fromDate}
                    max={toDate}
                  />
                </th>
                <th>
                  Status <br />
                  <select
                    className="form-select form-select-md"
                    value={statusFilter}
                    onChange={(e) => {setStatusFilter(e.target.value); setOffset(1); setIsAllSelected(false); setSelectedOrders([]);}}
                  >
                    <option value="">All Status</option>
                    <option value="Created">Created</option>
                    <option value="Assigned to agent">Assigned</option>
                    <option value="Rejected by admin">Rejected</option>
                    <option value="Pickup Completed">Payment Pending</option>
                    <option value="Order Placed">Order Placed</option>
                    <option value="processed">Payment Successful</option>
                  </select>
                </th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody style={{ fontSize: "14px" }}>
              {orderData
                .map((data, index) => {
                  // const isChecked = selectedOrders.includes(data.order_id);
                  // const isPaymentPending = data.status === 'Pickup Completed';
                  return (
                    <tr key={index}>
                      {/* <td>
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={() => handleSelectOrder(data.order_id)}
                          disabled={!isPaymentPending}
                        />
                      </td> */}
                      {/* <td>{(offset - 1) * 10 + index + 1}</td> */}
                      <td><Link to={data._id} style={{ color: "#73be44", cursor: "pointer" }}>{data.order_id}</Link><br />{data.customer_name}</td>
                      {/* <td>{data.customer_name}</td> */}
                      <td>{data.customer_mobile}</td>
                      <td>{data.agent_name}</td>
                      {/* <td>{data.city}</td> */}
                      {/* <td>{data.organisation}</td> */}
                      <td>{data.location} , {data.landmark}</td>
                      <td>{data.schedule_date}</td>
                      <td>{data.status === 'processed' ? 'Payment Successful' : data.status === 'Pickup Completed' ? 'Payment Pending' : data.status}</td>
                      {/* <td>
                        <Link to={data._id} className="btn btn-sm" style={{background:"#73be44", color:"#fff"}}><i className="fa-solid fa-arrow-right"/></Link>
                      </td> */}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      {/* Pagination for order table */}
      <div className='d-flex justify-content-between pb-0'>
        <div className='p-2'>
          Showing {startItem} to {endItem} of {totalItems} items
        </div>
        <div className='p-2'>
          <Pagination className="mb-0">
          <Pagination.First onClick={() => handlePageChange(1)} disabled={offset === 1} />
          <Pagination.Prev onClick={() => handlePageChange(offset - 1)} disabled={offset === 1} />
          {renderPaginationItems()}
          <Pagination.Next onClick={() => handlePageChange(offset + 1)} disabled={offset === totaloffset} />
          <Pagination.Last onClick={() => handlePageChange(totaloffset)} disabled={offset === totaloffset} />
          </Pagination>
        </div>
      </div>

      <Modal
        size="md"
        show={sattle}
        onHide={() => setSattle(false)}
        aria-labelledby="example-modal-sizes-title-md" centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">Payment Settlement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={3}>
                  <Form.Label>Mode Of Payment </Form.Label>
                </Col>
                <Col xs={12} md={9}>
                <Form.Control as="select" className='form-select form-select-md' value={mode} onChange={(e) => setMode(e.target.value)}>
                  <option value="" disabled >Select Mode</option>
                  <option value="Cash">Cash</option>
                  <option value="IMPS">IMPS</option>
                  <option value="NEFT">NEFT</option>
                  <option value="UPI">UPI</option>
                </Form.Control>
                </Col>
              </Row>
            </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Row>
                <Col xs={6} md={3}>
                  <Form.Label>Transaction Id</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                <Form.Control
                    type="text"
                    value={transctionid}
                    onChange={(e) => setTransactionId(e.target.value)}
                  />
                </Col>
              </Row>
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Row>
                  <Col xs={6} md={3}>
                    <Form.Label>Settlement Date</Form.Label>
                  </Col>
                  <Col xs={12} md={9}>
                  <Form.Control
                    type="date"
                    value={settledate}
                    onChange={(e) => setSettleDate(e.target.value)}
                  />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={3}>
                  <Form.Label>Notes</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                <textarea
                    className="form-control"
                    id="example-modal-sizes-title-md"
                    type="text-area"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    rows="3"
                  />
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSettle}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
